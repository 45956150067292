import React from 'react';
import Layout from '../layouts/main/Layout';
import Grid from '../layouts/portfolio/Grid';
import data from '../assets/images/characters/characters.json';

const characterDesign = ({ ...props }) => {
  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <Grid data={data} />
    </Layout>
  );
};

export default characterDesign;
